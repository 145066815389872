import { ColumnDefinitionDraft, Theme } from '../types';
import logger from '../utils/logger';
const serverUrl = `${import.meta.env.VITE_SERVER_URL}`;

// const wait = async (time: number): Promise<void> => {
//   return new Promise((resolve) => {
//     setTimeout(resolve, time);
//   });
// }

export const fetchHealthStatus = async () => {
  const response = await fetch(`${serverUrl}/health`);
  if (!response.ok) {
    throw new Error('Network issue, please try again later.');
  }
  return response;
};

const handleResponse = async (response: Response) => {
  if (response.ok) {
    return response.json();
  } else if (response.status >= 400 && response.status < 500) {
    const json = await response.json();
    throw new Error(json.error);
  } else {
    throw new Error();
  }
};

const postEndpointApi = async (endpoint: string, signal?: AbortSignal) => {
  try {
    const response = await fetch(`${serverUrl}/${endpoint}`, {
      method: 'POST',
      signal,
    });

    return handleResponse(response);
  } catch (error) {
    logger.error('error: ', error);
    throw Error();
  }
};

export const updateColumnDefinitionApi = async (
  projectId: string,
  jsonData: UpdateJobVersionRequestBody
) => jsonDataApi(jsonData, `update_job_version_api/${projectId}`);

export const getApi = async (endpoint: string) => {
  try {
    const response = await fetch(`${serverUrl}/${endpoint}`);
    return handleResponse(response);
  } catch (error) {
    logger.error('error: ', error);
    throw Error();
  }
};

const formDataApi = async (formData: FormData, endpoint: string) => {
  try {
    const response = await fetch(`${serverUrl}/${endpoint}`, {
      method: 'POST',
      body: formData,
    });

    return handleResponse(response);
  } catch (error) {
    logger.error('error: ', error);
    throw Error();
  }
};

const jsonDataApi = async (
  jsonData: object,
  endpoint: string,
  httpVerb: string = 'POST',
  signal?: AbortSignal
) => {
  try {
    const response = await fetch(`${serverUrl}/${endpoint}`, {
      method: httpVerb,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonData),
      signal,
    });

    return handleResponse(response);
  } catch (error) {
    logger.error('error: ', error);
    throw Error();
  }
};

export const createProjectApi = async (
  organizationId: number,
  projectName: string
) => jsonDataApi({ name: projectName }, `create_project/${organizationId}`);

export const updateProjectApi = async (projectId: number, jsonData: object) =>
  jsonDataApi(jsonData, `update_project/${projectId}`);

export interface UpdateJobVersionRequestBody {
  dashboard_title?: string;
  dashboard_logo_url?: string;
}

export const updateJobVersionApi = async (
  projectId: string,
  jsonData: UpdateJobVersionRequestBody
) => jsonDataApi(jsonData, `update_job_version_api/${projectId}`);

export const createUserContentApi = async (formData: FormData, jobId: string) =>
  formDataApi(formData, `create_user_content/${jobId}`);

export const uploadDataAndSetupProjectApi = async (
  formData: FormData,
  projectId: string
) =>
  formDataApi(formData, `projects/${projectId}/upload_data_and_setup_project`);

export const resetProjectApi = async (projectId: string) =>
  postEndpointApi(`reset_project/${projectId}`);

export const initJobApi = async (
  projectId: string,
  columnDefinitionId: string
) =>
  postEndpointApi(`projects/${projectId}/initialize_job/${columnDefinitionId}`);

interface ReAnalyzeQuestionResponse {
  job: {
    [key: string]: string | number;
  };
  job_version: {
    [key: string]: string | number;
  };
}
export const reAnalyzeQuestionApi = async (projectId: string, jobId: string) =>
  postEndpointApi(`projects/${projectId}/reanalyze_job/${jobId}`).then(
    (res: ReAnalyzeQuestionResponse) =>
      Object.fromEntries(
        Object.entries(res).map(([key, value]) => [
          snakeCaseToCamelCase(key),
          mapObjectKeysFromSnakeCaseToCamelCase(value),
        ])
      )
  );
export const restoreVersionApi = async (
  projectId: string,
  jobId: string,
  jobVersionId: string
) =>
  postEndpointApi(
    `projects/${projectId}/restore_version/${jobId}/${jobVersionId}`
  ).then((res: ReAnalyzeQuestionResponse) =>
    Object.fromEntries(
      Object.entries(res).map(([key, value]) => [
        snakeCaseToCamelCase(key),
        mapObjectKeysFromSnakeCaseToCamelCase(value),
      ])
    )
  );

export const deleteColumnDefinitionsApi = async (projectId: string) =>
  postEndpointApi(`projects/${projectId}/delete_column_definitions`);

export const saveThemesApi = async (themes: Theme[], jobVersionId: string) =>
  jsonDataApi(themes, `save_themes/${jobVersionId}`);

const camelCaseToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => {
    return `_${letter.toLowerCase()}`;
  });
};

const snakeCaseToCamelCase = (str: string) => {
  return str.replace(/(_[a-z])/g, (group) =>
    group.toUpperCase().replace('_', '')
  );
};

const mapObjectKeysFromSnakeCaseToCamelCase = (obj: {
  [key: string]: string | number;
}) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      snakeCaseToCamelCase(key),
      value,
    ])
  );
};

const prepareColumnDefinitionsBody = (
  columnDefs: ColumnDefinitionDraft[],
  projectId: string
) => {
  const snakeCaseColumnDefs = columnDefs.map((c) => {
    const snakeCaseKeys = Object.fromEntries(
      Object.entries(c).map(([key, value]) => [
        camelCaseToSnakeCase(key),
        value,
      ])
    );
    return { ...snakeCaseKeys, project_id: projectId };
  });

  const body = {
    columns: snakeCaseColumnDefs,
  };
  return body;
};

export const createOrUpdateColumnDefinitionsApi = async (
  columnDefs: ColumnDefinitionDraft[],
  projectId: string
) => {
  const body = prepareColumnDefinitionsBody(columnDefs, projectId);
  return jsonDataApi(body, `projects/${projectId}/column_definitions`);
};

export const setupProjectApi = async (
  columnDefs: ColumnDefinitionDraft[],
  projectId: string
) => {
  const body = prepareColumnDefinitionsBody(columnDefs, projectId);
  return jsonDataApi(body, `projects/${projectId}/setup_project`);
};

export const getColumnDefinitionsApi = async (projectId: string) => {
  const columnDefs = await getApi(`projects/${projectId}/column_definitions`);
  return columnDefs.map((c: any) => {
    const camelCaseKeys = Object.fromEntries(
      Object.entries(c).map(([key, value]) => [
        snakeCaseToCamelCase(key),
        value,
      ])
    );
    return camelCaseKeys;
  });
};

export const getJobsDisplayApi = async (projectId: string) => {
  const jobsDisplay = await getApi(`projects/${projectId}/jobs_display`);
  return jobsDisplay.map((c: any) => {
    const camelCaseKeys = Object.fromEntries(
      Object.entries(c).map(([key, value]) => [
        snakeCaseToCamelCase(key),
        value,
      ])
    );
    return camelCaseKeys;
  });
};

export const getJobDisplayApi = async (projectId: string, jobId: string) => {
  const jobDisplay = await getApi(`projects/${projectId}/${jobId}/job_display`);
  return Object.fromEntries(
    Object.entries(jobDisplay).map(([key, value]) => [
      snakeCaseToCamelCase(key),
      value,
    ])
  );
};

export const fetchProjectApi = async (projectId: string) =>
  getApi(`get_project/${projectId}`);

export const getCurrentUserApi = async () => getApi(`get_current_user`);

export const fetchDashboardApi = async (jobVersionId: string) =>
  getApi(`get_dashboard_v2/${jobVersionId}`);

export const fetchAggregateDashboardApi = async (
  orgId: string,
  filterBy?: string,
  filterValue?: string
) =>
  filterBy && filterValue
    ? getApi(
        `get_aggregate_dashboard/${orgId}?filter_by=${filterBy}&filter_value=${filterValue}`
      )
    : getApi(`get_aggregate_dashboard/${orgId}`);

export const fetchProjectsApi = async (organizationId: number) =>
  getApi(`get_projects/${organizationId}`);

export const getOrganizationApi = async (organizationId: number) =>
  getApi(`get_user_organization/${organizationId}`);

export const fetchThemesApi = async (jobVersionId: string) =>
  getApi(`get_themes/${jobVersionId}`);

export const fetchUserContentCount = async (jobId: string) =>
  getApi(`get_user_content_count/${jobId}`);

export const getNonEmptyUserContentCount = async (jobId: string) =>
  getApi(`get_non_empty_user_content_count/${jobId}`);

export const removeUserContentApi = async (jobId: string) =>
  postEndpointApi(`remove_user_content/${jobId}`);

export const getJobConfigurationApi = async (jobVersionId: string) =>
  getApi(`get_job_configuration/${jobVersionId}`);

export const createJobConfigurationApi = async (
  jsonData: object,
  jobVersionId: string
) => jsonDataApi(jsonData, `create_job_configuration/${jobVersionId}`);

export const getFirstJobVersionForProjectApi = async (projectId: string) =>
  getApi(`get_first_job_version_for_project/${projectId}`);

export const getJobVersionApi = async (jobVersionId: string) =>
  getApi(`get_job_version/${jobVersionId}`);

export const getJobVersionsForJobApi = async (jobId: string) =>
  getApi(`get_job_versions_for_job/${jobId}`).then((r) =>
    r.map(mapObjectKeysFromSnakeCaseToCamelCase)
  );

export const generateThemesApi_v2 = async (jobVersionId: string) =>
  postEndpointApi(`generate_themes_sync_v2/${jobVersionId}`);

export const multiTagDataApi = async (themes: Theme[], jobVersionId: string) =>
  jsonDataApi(themes, `multi_tag_data_v2/${jobVersionId}`);

export const multiTagDataSampleSyncApi = async (
  themes: Theme[],
  jobVersionId: string
) => jsonDataApi(themes, `multi_tag_data_sync_v2/${jobVersionId}`);

export const tagPageApi = async (
  jobVersionId: string,
  pageParam: number,
  withRandomOrder: boolean = false,
  signal: AbortSignal
) => {
  return postEndpointApi(
    `tag_page/${jobVersionId}?cursor=` +
      pageParam +
      `&with_random=${withRandomOrder}`,
    signal
  );
};

export const translateUserContentsApi = async (jobVersionId: string) => {
  return postEndpointApi(`translate_user_contents/${jobVersionId}`);
};

export const reshuffleTagSampleOrderApi = async (jobVersionId: string) =>
  postEndpointApi(`reshuffle_tag_sample_order/${jobVersionId}`);

export const getBatchTagPromptExampleApi = async (
  themes: Theme[],
  jobVersionId: string
) =>
  jsonDataApi(
    themes,
    `create_batch_tag_prompt_example_for_job_version/${jobVersionId}`
  );

export const testTriggerTaskState = async () => {
  const response = await fetch(`${serverUrl}/task_simulate_api`);
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.error);
  }
  return response.json();
};

export const fetchTaskState = async (taskId: string) => {
  const response = await fetch(`${serverUrl}/result/${taskId}`);
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.error);
  }
  return response.json();
};
