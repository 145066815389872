import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { TagResult } from '../../types';
import ClearButton from '../buttons/ClearButton';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import P1 from '../newTextComponents/P1';
import P3 from '../newTextComponents/P3';

interface FeedbackItemProps {
  tagResult: TagResult;
  shouldAlignRight?: boolean;
  codebookLanguage: 'Hebrew' | 'English';
}

const FeedbackItem: React.FC<FeedbackItemProps> = ({
  tagResult,
  shouldAlignRight,
  codebookLanguage,
}) => {
  const [showTranslation, setShowTranslation] = useState(false);
  const hasTranslation = !!tagResult.translatedText;

  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  const displayText =
    showTranslation && tagResult.translatedText
      ? tagResult.translatedText
      : tagResult.text;

  const sourceLanguage = tagResult.sourceLanguageISOCode;

  return (
    <ItemContainer>
      <FeedbackText $shouldAlignRight={shouldAlignRight}>
        {displayText}
      </FeedbackText>

      <TagsContainer $shouldAlignRight={codebookLanguage === 'Hebrew'}>
        {tagResult.themes.map((theme, index) => (
          <Tag key={index}>{theme}</Tag>
        ))}
      </TagsContainer>
      {hasTranslation && sourceLanguage && (
        <LanguageISOCodeButton
          onClick={toggleTranslation}
          label={sourceLanguage || ''}
          style={{ padding: '4px 16px' }}
          $showTranslation={showTranslation}
        />
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled(VerticalFlex)`
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  gap: 12px;
  position: relative;
`;

const LanguageISOCodeButton = styled(ClearButton)<{
  $showTranslation: boolean;
}>`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px 16px;
  ${({ $showTranslation }) =>
    $showTranslation
      ? `background-color: ${Colors.WHITE};`
      : `background-color: ${Colors.P40};`}
`;

const FeedbackText = styled(P1)<{ $shouldAlignRight?: boolean }>`
  ${({ $shouldAlignRight }) =>
    $shouldAlignRight ? 'text-align: right; direction: rtl;' : ''}
`;

const Tag = styled(P3)`
  padding: 4px 16px;
  background-color: ${Colors.P40};
  border-radius: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TagsContainer = styled(CenteredHorizontalFlex)<{
  $shouldAlignRight: boolean;
}>`
  flex-wrap: wrap;
  gap: 8px;
  direction: ${({ $shouldAlignRight }) => ($shouldAlignRight ? 'rtl' : 'ltr')};
`;

export default FeedbackItem;
