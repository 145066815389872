import LottieView from 'lottie-react-web';
import React from 'react';

const ButtonLoadingAnimation: React.FC = ({}) => {
  return (
    <LottieView
      options={{
        animationData: require('./button_loading_animation.json'),
      }}
    />
  );
};

export default ButtonLoadingAnimation;
