import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import Label from './Label';
import CenteredHorizontalFlex from './containers/CenteredHorizontalFlex';

interface ToggleWithLabelProps {
  label: string;
  name: string;
}

const ToggleWithLabel: React.FC<ToggleWithLabelProps> = ({ label, name }) => {
  return (
    <Container>
      <StyledLabel>{label}</StyledLabel>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <ToggleWrapper>
            <ToggleInput
              type='checkbox'
              {...field}
              checked={field.value === true}
              onChange={(e) =>
                field.onChange({
                  target: {
                    name: field.name,
                    value: e.target.checked,
                  },
                })
              }
              id={name}
            />
            <ToggleSlider />
          </ToggleWrapper>
        )}
      </Field>
    </Container>
  );
};

export default ToggleWithLabel;

const Container = styled(CenteredHorizontalFlex)`
  margin-bottom: 16px;
  justify-content: space-between;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  color: ${Colors.PRIMARY};
`;

const ToggleWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${Colors.P100};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${Colors.P100};
  }

  &:checked + span:before {
    transform: translateX(16px);
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 16px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
