import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import ButtonLoadingAnimation from './ButtonLoadingAnimation';

interface ClearButtonProps {
  isGray?: boolean;
  disabled?: boolean;
  loading?: boolean;
  label: string;
  onClick: VoidFunction;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  textColor?: string;
  withBorder?: boolean;
  className?: string;
}

const ClearButton: React.FC<ClearButtonProps> = ({
  onClick,
  isGray = false,
  disabled = false,
  label,
  style,
  textStyle,
  textColor,
  withBorder,
  className,
  loading,
}) => {
  return (
    <Button
      style={style}
      onClick={onClick}
      disabled={disabled}
      type='button'
      withBorder={withBorder}
      className={className}
    >
      {loading ? (
        <ButtonLoadingAnimation />
      ) : (
        <TextLabel style={textStyle} isGray={isGray} textColor={textColor}>
          {label}
        </TextLabel>
      )}
    </Button>
  );
};

export default ClearButton;

const Button = styled.button<{ withBorder?: boolean }>`
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 24px;
  height: 39px;
  padding: 12px 20px;
  gap: 10px;
  border: 1px solid ${Colors.GRAY_40};
  border-radius: 100px;

  :active {
    ${({ withBorder }) =>
      withBorder ? `background-color: ${Colors.PRIMARY};` : ''};
  }
  :hover {
    ${({ withBorder }) =>
      withBorder
        ? `background-color: ${Colors.WHITE}; border: 1px solid ${Colors.BORDER};`
        : ''};
  }
`;

const TextLabel = styled.span<{
  isGray?: boolean;
  textColor?: string;
}>`
  color: ${Colors.GRAY_100};
  font-size: 12px;
  line-height: 15px;
`;
